/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import Vue from 'vue';
import Vuex from 'vuex';

import account from '@/store/modules/account';
import credentials from '@/store/modules/credentials';
import labels from '@/store/modules/labels';
import workers from '@/store/modules/workers';
import workflows from '@/store/modules/workflows';
import config from '@/store/modules/config';
import user from '@/store/modules/user';
import crmIntegration from '@/store/modules/crmIntegration';
import integrations from '@/store/modules/integrations';
import signatures from '@/store/modules/emailSignatures';
import customDomains from '@/store/modules/customDomains';
import scenario from '@/store/modules/scenario';
import health from '@/store/modules/health';
import mobile from '@/store/modules/mobile';
import vox from '@/store/modules/vox';
import view from '@/store/modules/view';
import dashboard from '@/store/modules/dashboard';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    account,
    credentials,
    labels,
    workers,
    workflows,
    config,
    user,
    crmIntegration,
    integrations,
    signatures,
    scenario,
    health,
    mobile,
    view,
    vox,
    dashboard,
    customDomains,
  },
  state: {
    letRedirect: false,
    isModalOpen: false,
    modalName: '',
    theme: '',
  },
  getters: {
    letRedirect: state => {
      return state.letRedirect;
    },
    isModalOpen: state => {
      return state.isModalOpen;
    },
    modalName: state => {
      return state.modalName;
    },
    theme: state => {
      return state.theme;
    },
  },
  mutations: {
    OPEN_MODAL (state, payload) {
      state.isModalOpen = payload.isOpen;
      state.modalName = payload.name;
    },
    SET_LET_REDIRECT (state, payload) {
      state.letRedirect = payload;
    },
    SWITCH_THEME (state, theme) {
      document.documentElement.setAttribute('data-theme', theme);
      localStorage.setItem('theme', theme);
      state.theme = theme;
    },
  },
  actions: {
    OPEN_MODAL ({ commit }, payload) {
      commit('OPEN_MODAL', payload);
    },
    SET_LET_REDIRECT ({ commit }, payload) {
      commit('SET_LET_REDIRECT', payload);
    },
    SWITCH_THEME ({ commit }, theme) {
      commit('SWITCH_THEME', theme);
    },
  },
});

export default store;
export const useStore = () => store;
