import { getSpaceFeatures } from '@/api/spaceMethods';
import { Tiers } from '@/enums/pricing';
import { FeatureStatus } from '@/components/adminPage/whiteLabelsPage/static/featureStatus';
import { FEATURES } from '@/data/pricing/features';
import { getFeaturesByCategory } from '@/components/adminPage/whiteLabelsPage/static/static';
import store from '@/store';
import { computed, watch } from 'vue';
watch(() => store.getters['workflows/workflowsList'], () => {
    if (tierFeatures.features) {
        tierFeatures.setFilteredFeatures();
        tierFeatures.setLimitFeatures();
    }
});
watch(() => store.getters['integrations/integrationsList'], () => {
    if (tierFeatures.features) {
        tierFeatures.setFilteredFeatures();
        tierFeatures.setLimitFeatures();
    }
});
class TierFeatures {
    features;
    tier;
    filteredFeatures;
    statuses;
    constructor() {
        this.tier = null;
        this.features = [];
        this.statuses = {};
        this.filteredFeatures = [];
    }
    async initiate(tier, account) {
        this.features = await getSpaceFeatures();
        const featuresIdx = this.features.findIndex(f => f.tier === tier);
        if (featuresIdx === -1) {
            this.features.push({
                tier,
                features_settings: account.features_settings,
                local_prices: {},
            });
        }
        else {
            this.features[featuresIdx].features_settings = account.features_settings;
        }
        this.tier = tier;
        this.features = this.features.sort((a, b) => a.tier - b.tier);
        this.setFilteredFeatures();
        const featuresStatuses = this._setFeatureStatuses(this.filteredFeatures);
        this.statuses = this._setFeatures(featuresStatuses);
    }
    setFilteredFeatures() {
        const workflowsList = computed(() => store.getters['workflows/workflowsList'] || []);
        const integrationsList = computed(() => store.getters['integrations/integrationsList'] || []);
        this.filteredFeatures = [...this.features].map(f => {
            const simpleFeatures = {};
            // убираем лишнюю вложенность
            Object.assign(simpleFeatures, ...Object.values(f.features_settings.simple_features));
            return {
                ai: Object.values(f.features_settings.simple_features.ai_features),
                dialer: Object.values(f.features_settings.simple_features.dialer_features),
                campaigns: !f.features_settings.limited_features.campaigns.is_limited || workflowsList.value.length < f.features_settings.limited_features.campaigns.limit,
                email_integrations: !f.features_settings.limited_features.email_integrations.is_limited || integrationsList.value.length < f.features_settings.limited_features.email_integrations.limit,
                default: getFeaturesByCategory(f.features_settings.credits_features, 'default').default,
                features_settings: simpleFeatures,
                tier: f.tier,
                local_prices: f.local_prices,
                is_enabled: f.is_enabled || false,
            };
        });
    }
    _setFeatureStatuses(features) {
        return features.map(feature => {
            return feature.features_settings;
        });
    }
    _setFeatures(featuresStatuses) {
        let features = {};
        FEATURES.forEach((feature) => {
            const statusesList = [];
            const featureTier = featuresStatuses.reduce((prev, features) => {
                statusesList.push(features[feature]);
                const featureTiers = { [feature]: statusesList };
                return Object.assign(prev, featureTiers);
            }, {});
            features = Object.assign(features, featureTier);
        });
        return this._enableFeatures(features, this.tier);
    }
    _findCurrentTierIndex(tier) {
        // берем валуе от энама, фильтруем его по тирам приходящим с бэка и ищем от них индекс нынешнего тира
        // чтоб сопоставить с массивами фичей и понять доступна ли фича в этом тире
        return Object.values(Tiers).filter(t => this.features.some(f => f.tier === t)).findIndex(t => t === tier);
    }
    _enableFeatures(features, tier) {
        const currentTierIndex = this._findCurrentTierIndex(tier);
        const featuresStatus = {};
        for (const featureName in features) {
            if (features[featureName][currentTierIndex]) {
                featuresStatus[featureName] = new FeatureStatus(true, false);
            }
            else if (features[featureName].every(f => !f)) {
                featuresStatus[featureName] = new FeatureStatus(false, false);
            }
            else {
                featuresStatus[featureName] = new FeatureStatus(false, true);
            }
        }
        return featuresStatus;
    }
    setLimitFeatures() {
        const limitFeatures = ['campaigns', 'email_integrations'];
        let features = {};
        limitFeatures.forEach((feature) => {
            const statusesList = [];
            const featureTier = this.features.reduce((prev, features) => {
                const limitedFeature = features.features_settings.limited_features[feature];
                const check = this._checkLimitedFeature(feature, limitedFeature);
                statusesList.push(check);
                const featureTiers = { [feature]: statusesList };
                return Object.assign(prev, featureTiers);
            }, {});
            features = Object.assign(features, featureTier);
        });
        const statuses = this._enableFeatures(features, this.tier);
        this.statuses = Object.assign(this.statuses, statuses);
    }
    _checkLimitedFeature(feature, featureLimit) {
        const workflowsList = computed(() => store.getters['workflows/workflowsList'] || []);
        const integrationsList = computed(() => store.getters['integrations/integrationsList'] || []);
        switch (feature) {
            case 'campaigns':
                return Boolean(!featureLimit.is_limited || (workflowsList.value?.length || 0) < featureLimit.limit);
            case 'email_integrations':
                return Boolean(!featureLimit.is_limited || (integrationsList.value?.length || 0) < featureLimit.limit);
            default:
                return true;
        }
    }
}
const tierFeatures = new TierFeatures();
export { tierFeatures };
