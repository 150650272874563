<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <validation-observer v-slot="{ passes }">
    <form
      ref="vldParent"
      class="vld-parent"
      @submit.prevent="passes(saveChanges)"
    >
      <basic-avatar
        class="mb-2"
        :avatar="avatarImg"
        @change="uploadAvatarHandler"
      />
      <div class="preferences-page__form-control">
        <label class="text_m">
          {{ $t('workspaceSettings.settingsList.basicInfo.workspaceName.label') }}
          <span class="preferences-page__form-required">*</span>
        </label>
        <default-input
          :value.sync="formData.name"
          name="Workspace name"
          validation-string="required"
          class="preferences-page__form-input"
          size="s"
          :placeholder="$t('workspaceSettings.settingsList.basicInfo.workspaceName.placeholder')"
        />
      </div>
      <div class="preferences-page__form-control">
        <label
          class="text_m"
          @click="formData.is_deduplicate_profiles = !formData.is_deduplicate_profiles"
        >
          {{ $t('workspaceSettings.settingsList.basicInfo.deduplicate.label') }}
          <help-icon v-bind="prospectsDedupeTooltipOptions" />
        </label>
        <div class="preferences-page__form-input">
          <default-switcher
            class="left"
            :is-checked="formData.is_deduplicate_profiles"
            @change="formData.is_deduplicate_profiles = !formData.is_deduplicate_profiles"
          />
        </div>
      </div>
      <div class="preferences-page__form-control">
        <label
          class="text_m"
          @click="formData.is_email_validation_enabled = !formData.is_email_validation_enabled"
        >
          {{ $t('workspaceSettings.settingsList.basicInfo.validateEmail.label') }}
        </label>
        <div class="preferences-page__form-input">
          <default-switcher
            class="left"
            :is-checked="formData.is_email_validation_enabled"
            @change="formData.is_email_validation_enabled = !formData.is_email_validation_enabled"
          />
        </div>
      </div>
      <div
        v-if="['divo', 'funnelflo'].includes(account.brand)"
        class="preferences-page__form-control"
      >
        <label
          class="text_m"
          @click="isLiApproveEnabled = !isLiApproveEnabled"
        >
          {{ $t('workspaceSettings.settingsList.basicInfo.requireApproval.label') }}
        </label>
        <div class="preferences-page__form-input">
          <default-switcher
            class="left"
            :is-checked="isLiApproveEnabled"
            @change="isLiApproveEnabled = !isLiApproveEnabled"
          />
        </div>
      </div>
      <default-button
        class="margin-left"
        type="submit"
        :title="$t('actions.saveChanges')"
        color="primary"
        form="rectangle"
        size="m"
      />
    </form>
  </validation-observer>
</template>
<script>
  import DefaultInput from '@/components/base/uiKit/DefaultInput';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import BasicAvatar from '@/components/preferencesPage/user/basic/BasicAvatar';
  import HelpIcon from '@/components/helpIcon/HelpIcon.vue';
  import { mapGetters } from 'vuex';
  import { loaderMixin } from '@/mixins/loaderMixin';
  import { updateSpace, uploadSpaceAvatar } from '@/api/spaceMethods';
  import { ValidationObserver } from 'vee-validate';
  import DefaultSwitcher from '@/components/base/uiKit/DefaultSwitcher.vue';
  import { TooltipPosition } from '@/plugins/tooltip/enums';
  import { setLiApprove } from '@/api/AdminMethods';

  export default {
    components: {
      DefaultInput,
      DefaultButton,
      ValidationObserver,
      DefaultSwitcher,
      BasicAvatar,
      HelpIcon,
    },
    mixins: [loaderMixin],
    data () {
      return {
        formData: {
          name: '',
          is_deduplicate_profiles: false,
          is_email_validation_enabled: false,
          avatarImg: null,
        },
        isLiApproveEnabled: false,
        prospectsDedupeTooltipOptions: {
          content: 'Once a prospect is added to a campaign by an account in this workspace, other accounts will be unable to add the same prospect to their campaigns when this toggled "on". Changes to this setting will not apply retroactively.',
          tooltipMaxWidth: 400,
          tooltipPosition: TooltipPosition.TOP_RIGHT,
        },
      };
    },
    computed: {
      ...mapGetters({
        account: 'account/account',
      }),
    },
    created () {
      this.formData.name = this.account.name;
      this.formData.is_deduplicate_profiles = this.account.is_deduplicate_profiles;
      this.formData.is_email_validation_enabled = this.account.is_email_validation_enabled;
      this.isLiApproveEnabled = this.account.is_li_approve;
    },
    beforeMount () {
      this.avatarImg = this.account.avatar ? this.account.avatar + '?no_cache=true' : null;
    },
    methods: {
      async saveChanges () {
        this.loaded = false;
        try {
          if (this.isLiApproveEnabled !== this.account.is_li_approve) {
            await setLiApprove(this.$route.params.id, this.isLiApproveEnabled);
          }
          const newSpace = await updateSpace(this.$route.params.id, this.formData);
          this.$store.commit('account/UPDATE_SPACES_LIST', newSpace);
          this.$noty.success(
            this.$t('workspaceSettings.settingsList.basicInfo.notifications.workspaceUpdated')
          );
        } catch (e) {
          this.$noty.error(e.message);
        } finally {
          this.loaded = true;
        }
      },
      async uploadAvatarHandler (e) {
        try {
          const file = e.target.files[0];
          this.avatarImg = URL.createObjectURL(file);
          await uploadSpaceAvatar(file);

          this.$noty.success(
            this.$t('workspaceSettings.settingsList.basicInfo.notifications.avatarChanged')
          );
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
    },
  };
</script>
