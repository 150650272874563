<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <default-modal
    :show="show"
    :name="key"
    width="800px"
    @update:show="toggleModal"
  >
    <div class="modal">
      <div class="modal__header mb-3">
        <p class="modal__title">
          {{ $t('modals.continueStep.resumeCampaign') }}
        </p>
      </div>
      <p
        v-if="showSteps"
        class="continue-modal__subtitle secondary-text mb-2 mt-1"
      >
        {{ $t('modals.continueStep.selectStep') }}
      </p>
      <div class="continue-modal__steps mb-3">
        <continue-step
          v-for="(action, i) in steps"
          :key="i"
          :action="action"
          :index="i"
          :active="i === chosenIndex"
          :disabled="action.done"
          @click.native="choose(i)"
        />
      </div>
      <div
        class="modal__buttons"
      >
        <p
          class="primary-btn"
          @click="resume"
        >
          {{ $t('modals.continueStep.resumeForName', { name }) }}
        </p>
        <p
          :class="['primary-btn  primary-btn_gray']"
          @click="toggleModal(false)"
        >
          {{ $t('actions.cancel') }}
        </p>
      </div>
    </div>
  </default-modal>
</template>

<script setup>
  import { ref } from 'vue';
  import DefaultModal from '@/components/base/DefaultModal.vue';
  import ContinueStep from '@/modals/ContinueModal/ContinueStep.vue';

  const props = defineProps({
    show: {
      type: Boolean,
      default: false,
    },
    steps: {
      type: Array,
      default: null,
    },
    showSteps: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    localKey: {
      type: String,
      default: 'continueModal',
    },
  });

  const emit = defineEmits(['update:show', 'resume']);

  const key = ref(props.localKey);

  const toggleModal = (isActive) => {
    emit('update:show', isActive);

    if (!isActive) {
      key.value = `${props.localKey} ${new Date().getTime()}`;
    }
  };

  const chosenIndex = ref(0);

  const choose = (index) => {
    if (!props.steps[index].done) {
      chosenIndex.value = index;
    }
  };

  const resume = () => {
    emit('resume', chosenIndex.value);
  };
</script>

<style lang="scss" scoped>
  .continue-modal {
    &__steps {
      overflow: auto;
      display: grid;
      grid-template-columns: minmax(150px, 1fr) minmax(150px, 1fr);
      row-gap: 15px;
      column-gap: 15px;
      padding: 8px;
      overflow: auto;
      max-height: calc(100vh - 400px);
    }
    &__subtitle{
      font-size:  1.33rem;
    }
  }
</style>
<style lang="scss">
.continue-modal{
  .modal__header{
    margin-bottom: 0px;
  }
}
</style>
