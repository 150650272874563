export const FEATURES = [
  'icebreakers',
  'calling',
  'sms',
  'smart_sequences',
  'video',
  'dash_tasks',
  'prospect_finder',
  'connection_booster',
  'link_tracking',
  'enrichment',
  'webhook_integration',
  'zapier_integration',
  'email_automation',
  'email_warming',
  'social_targeting',
  'post_targeting',
  'event_targeting',
  'group_targeting',
  'api_targeting',
  'native_crm',
  'pipedrive_crm',
  'hubspot_crm',
  'ai_magic_campaigns',
  'ai_assistant_in_campaigns',
  'ai_assistant_in_inbox',
  'ai_powered_comments',
  'ai_powered_labels',
  'ai_powered_personality_type',
  'social_automation',
];
