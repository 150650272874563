<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <default-tooltip
    ref="tooltip"
    class="pointer"
    :items="props.items"
    :data-closable="props.dataClosable"
    :max-height="props.maxHeight"
    :list-position="props.listPosition"
    @choose="choose"
  >
    <template #firstItemList>
      <slot name="firstItemList" />
    </template>

    <template #default>
      <default-button
        :title="props.title"
        :form="props.form"
        :size="props.size"
        :color="btnColor"
        :icon-name="props.iconName"
        :icon-color="props.iconColor"
        :has-right-icon="props.hasRightIcon"
        :is-fulled="props.isFulled"
        :is-disabled="props.isDisabled"
        :has-paddings="props.hasPaddings"
        :title-color="props.titleColor"
        :hovered-title-color="props.hoveredTitleColor"
        :background-color="props.backgroundColor"
        :hovered-background-color="props.hoveredBackgroundColor"
        :border-color="props.borderColor"
        :pointer-events="props.pointerEvents"
      >
        <template
          v-if="btnImg"
          #before
        >
          <default-avatar
            :photo-link="props.btnImg"
            size="xxxs"
          />
        </template>
      </default-button>
    </template>
  </default-tooltip>
</template>
<script setup>
  import DefaultTooltip from '@/components/base/uiKit/DefaultTooltip.vue';
  import DefaultAvatar from '@/components/base/uiKit/DefaultAvatar.vue';
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';
  import { computed, ref } from 'vue';

  const emit = defineEmits(['choose']);

  const props = defineProps({
    items: {
      type: Array,
      default: () => [],
    },
    dataClosable: {
      type: String,
      default: '',
    },
    maxHeight: {
      type: String,
      default: '',
    },
    counter: {
      type: [String, Number],
      default: 0,
    },
    counterColor: {
      type: String,
      default: 'brand',
    },
    tag: {
      type: String,
      default: 'button',
    },
    type: { // button, submit, reset
      type: String,
      default: 'button',
    },
    title: {
      type: String,
      default: '',
    },
    form: { // square, circle, rectangle, ellipse
      type: String,
      default: 'square',
    },
    size: { // в зависимости от формы: смотри css
      type: String,
      default: 'm',
    },
    color: { // primary, neutral, transparent, success, danger
      type: String,
      default: '', // !!! не ставить значение, иначе кастомные стили не встанут
    },
    iconName: { // box-icons
      type: String,
      default: '',
    },
    listPosition: {
      type: String,
      default: 'left', // left, center, right
    },
    hasRightIcon: {
      type: Boolean,
      default: false,
    },
    isFulled: { // width: 100%
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    hasPaddings: {
      type: Boolean,
      default: true,
    },
    titleColor: { // для нетиповой стилизации
      type: String,
      default: '',
    },
    hoveredTitleColor: { // для нетиповой стилизации
      type: String,
      default: '',
    },
    backgroundColor: { // для нетиповой стилизации
      type: String,
      default: '',
    },
    hoveredBackgroundColor: { // для нетиповой стилизации
      type: String,
      default: '',
    },
    borderColor: { // для нетиповой стилизации
      type: String,
      default: '',
    },
    pointerEvents: { // для нетиповой стилизации
      type: Boolean,
      default: true,
    },
    activeColor: {
      type: String,
      default: '',
    },
    btnImg: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: '',
    },
    listPosition: {
      type: String,
      default: '',
    },
  });

  const btnColor = computed(() => props.activeColor && tooltip.value?.showBlocks[props.dataClosable]
    ? props.activeColor
    : props.color);
  const tooltip = ref(null);
  const choose = (item) => {
    emit('choose', item);
  };
</script>
