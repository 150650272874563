/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import moment from 'moment';
/**
 * Get timezone offset in minutes
 * @returns timezone offset in minutes, e.g. `+3 -> +180, -6 -> -360`
 */
export const getTimezoneOffset = () => {
    return new Date().getTimezoneOffset() * -1;
};
/**
 * Generate months periods
 * @param months Number of months periods
 * @param direction Forward = `1` / Backward = `-1`
 * @returns Array of months periods
 */
export const getMonthsPeriods = (months, direction = 1) => {
    const periods = [];
    // Protection for unexpected direction value
    const _direction = direction >= 0 ? 1 : -1;
    const now = moment(Date.now()).utc();
    for (let i = 0; i < months; i++) {
        periods.push({
            start: now.startOf('month').format('YYYY-MM-DD'),
            end: now.endOf('month').format('YYYY-MM-DD'),
        });
        now.add(_direction, 'month');
    }
    return periods;
};
