/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { useHttp } from '@/middleware/httpMiddleware';

async function getSpace () {
  const data = await useHttp(
    '/space/info',
    'GET',
    null,
    { no_cache: true }
  );

  return data.payload;
}

async function getSpacebyCode (code) {
  const data = await useHttp(
    '/space/info',
    'GET',
    null,
    {
      invite_code: code,
    }
  );

  return data.payload;
}
async function uploadSpaceAvatar (file) {
  const data = await useHttp(
    '/space/save_photo',
    'POST',
    {
      file,
    }
  );
  return data.payload;
}
async function createSpace (name) {
  const data = await useHttp(
    '/space/create',
    'POST',
    { name }
  );

  return data.payload;
}
async function inviteUser (email, spaceId) {
  const data = await useHttp(
    `/space/${spaceId}/send_invite`,
    'POST',
    { email }
  );

  return data.payload;
}
async function sendEmailInvite (
  spaceId = '', emails = [], permissions = 'member'
) {
  const data = await useHttp(
    `/space/${spaceId}/invites`,
    'POST',
    { emails, granted_access: permissions },
    {},
    true
  );

  return data.payload;
}
async function resendEmailInvite (spaceId, inviteId) {
  const data = await useHttp(
    `/space/${spaceId}/invites/${inviteId}/resend`,
    'POST'
  );

  return data.payload;
}
async function revokeEmailInvite (spaceId, inviteId) {
  const data = await useHttp(
    `/space/${spaceId}/invites/${inviteId}`,
    'DELETE'
  );

  return data.payload;
}
async function getEmailInvites (spaceId) {
  const data = await useHttp(
    `/space/${spaceId}/invites`,
    'GET'
  );

  return data.payload;
}
async function getCodeInvites (spaceId) {
  const data = await useHttp(
    `/space/${spaceId}/code_invites`,
    'GET'
  );

  return data.payload;
}
async function renewCodeInvite (spaceId, inviteId) {
  const data = await useHttp(
    `/space/${spaceId}/code_invites/${inviteId}/renew`,
    'POST'
  );

  return data.payload;
}
async function makePayoutRequest (request) {
  const data = await useHttp(
    '/space/make_payout_request',
    'POST',
    request
  );
  return data.payload;
}

async function updateSpace (id, space) {
  const data = await useHttp(
    `/space/${id}/update`,
    'POST',
    {
      space_object: JSON.stringify(space),
    }
  );
  return data.payload;
}

async function setOnboard (onboard) {
  const data = await useHttp(
    '/space/set_onboard',
    'POST',
    {
      onboard: JSON.stringify(onboard),
    }
  );
  return data.payload;
}

async function getSubscribe () {
  const data = await useHttp(
    '/space/subscriptions/validate',
    'POST'
  );
  return data.payload;
}
async function getCountries () {
  const data = await useHttp(
    '/space/get_free_proxy_counties',
    'GET',
    null
  );
  return data.payload;
}

async function deleteSpace (id) {
  const data = await useHttp(
    `/space/${id}/delete`,
    'POST',
    null
  );
  return data.payload;
}

async function getStripeSettings (priceId = '') {
  const data = await useHttp(
    '/billing/prices/app',
    'GET',
    null,
    {
      price_id: priceId,
    }

  );
  return data.payload;
}

async function getStripeEnrichment (priceId = '') {
  const data = await useHttp(
    '/billing/prices/credits',
    'GET',
    null,
    {
      price_id: priceId,
    }

  );
  return data.payload;
}

async function resetInviteCode (id) {
  const data = await useHttp(`/space/${id}/reset_invite_code`,
    'POST',
    null
  );

  return data.payload;
}

async function getSpaceInviteByCode (code) {
  const data = await useHttp(
    '/space/invites',
    'GET',
    null,
    {
      invite_code: code,
    }
  );

  return data.payload;
}

async function getConfirmInfo () {
  const data = await useHttp(
    '/billing/prices/extend_info',
    'GET'
  );

  return data.payload;
}

async function subscribe (currency, paymentPlan, paymentPeriod, newPriceId = '', priceId, credId) {
  const data = await useHttp('/billing/subscribe',
    'POST',
    {
      currency,
      payment_plan: paymentPlan,
      payment_period: paymentPeriod,
      new_price_id: newPriceId,
      price_id: priceId,
      credentials_id: credId,
    },
    null,
    true
  );

  return data.payload;
}

async function getSpaceFeatures () {
  const data = await useHttp(
    '/space/features',
    'GET',
    null
  );

  return data.payload;
}

export {
  getSpace,
  resetInviteCode,
  makePayoutRequest,
  updateSpace,
  setOnboard,
  getCountries,
  createSpace,
  inviteUser,
  sendEmailInvite,
  resendEmailInvite,
  revokeEmailInvite,
  getEmailInvites,
  getCodeInvites,
  renewCodeInvite,
  getSpacebyCode,
  deleteSpace,
  getStripeSettings,
  subscribe,
  getSubscribe,
  getStripeEnrichment,
  uploadSpaceAvatar,
  getSpaceInviteByCode,
  getSpaceFeatures,
  getConfirmInfo
};
