<template>
  <div
    :class="[
      'flex-row flex-gap-8',
      { 'background_success': isEditMode && isValid },
      { 'background_danger': isEditMode && !isValid }
    ]"
  >
    <div class="flex-row flex-gap-8">
      <i :class="['bx icon_size_16 color_tertiary', contact.icon]" />
      <p class="text_xs text_weight_accent">
        {{ contact.name }}
      </p>
    </div>

    <div class="flex-grow-1 overflow-hidden">
      <div
        v-if="isEditMode"
        class="flex-row flex-gap-8 flex-justify-content-end"
      >
        <input
          v-model="editValue"
          class="default-input text_xs flex-grow-1"
          type="text"
        >

        <default-button
          form="square"
          size="s"
          icon-name="bx bx-check-circle"
          title-color="color_tertiary"
          hovered-title-color="hovered_success"
          :has-paddings="false"
          :disabled="!isAbleToChange"
          @action="change"
        />
        <default-button
          form="square"
          size="s"
          icon-name="bx bx-x-circle"
          title-color="color_tertiary"
          hovered-title-color="hovered_danger"
          :has-paddings="false"
          @action="isEditMode = false"
        />
      </div>

      <div
        v-else
        class="flex-row flex-gap-8 flex-justify-content-end"
      >
        <p
          v-if="contact.name === 'Phone' && contact.value"
          class="text_xs link text_nowrap text_ellipsis"
          @click.stop="addPhone(contact.value)"
        >
          {{ contact.value || 'No ' + contact.name.toLowerCase() }}
        </p>
        <p
          v-else
          class="text_xs color_tertiary text_nowrap text_ellipsis"
        >
          {{ contact.value || 'No ' + contact.name.toLowerCase() }}
        </p>
        <default-button
          form="square"
          size="s"
          icon-name="bx bx-duplicate"
          title-color="color_tertiary"
          hovered-title-color="hovered_primary"
          :has-paddings="false"
          :disabled="!contact.value"
          @action="copyValue(contact.value)"
        />
        <default-button
          form="square"
          size="s"
          icon-name="bx bx-edit-alt"
          title-color="color_tertiary"
          hovered-title-color="hovered_primary"
          :has-paddings="false"
          @action="openEditMode"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import { eventBus } from '@/eventbus';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import { differentFunctionsMixin } from '@/mixins/differentFunctionsMixin';
  import {
    emailRegex,
    twitterRegex,
    isValidPhone
  } from '@/data/regexes';
  import { eventBusEvents } from '@/utils/eventBusEvents';

  export default {
    name: 'CustomItemContacts',
    components: {
      DefaultButton,
    },
    mixins: [differentFunctionsMixin],
    props: {
      contact: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        isEditMode: false,
        editValue: '',
      };
    },
    computed: {
      isValid () {
        return this.getValidStatus(this.contact.name.toLowerCase(), this.editValue);
      },
      isAbleToChange () {
        return this.isValid && this.contact.value !== this.editValue && this.editValue;
      },
    },
    methods: {
      addPhone (value) {
        this.$emit('add-phone');
        eventBus.$emit(eventBusEvents.ADD_NUMBER, { number: value, profile: this.contact.profile });
      },
      openEditMode () {
        this.isEditMode = true;
        this.editValue = this.contact.value;
      },
      getValidStatus (type, value) {
        switch (type) {
        case 'email':
          return emailRegex.test(value);
        case 'phone':
          return isValidPhone(value);
        case 'twitter':
          return twitterRegex.test(value);
        }
      },
      change () {
        this.$emit('change', this.editValue);
        this.editValue = '';
        this.isEditMode = false;
      },
    },
  };
</script>
