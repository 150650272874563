<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    class="quill-editor"
    @click="$emit('activate')"
  >
    <slot name="toolbar" />

    <div
      ref="editor"
      @click="textClick"
      @paste="$emit('paste')"
    />
    <slot />
  </div>
</template>
<script>
  import 'quill/dist/quill.core.css';
  import 'quill/dist/quill.snow.css';
  import { mixin } from '@/lib/vuejs-quill/src/index.js';

  export default {
    name: 'QuillTextarea',
    mixins: [mixin],
    props: {
      needShortOptions: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        maxOptions: {
          modules: {
            toolbar: {
              container: [
                [{ size: ['small', false, 'large', 'huge'] }],

                [{ font: [] }],
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['bold', 'italic', 'underline'],

                [{ align: [] }],
                ['link'],
              ],
            },
          },
          placeholder: this.$t('placeholders.typeYourMessage'),
          theme: 'snow',
        },
        shortOptions: {
          modules: {
            toolbar: {
              container: [
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['bold', 'italic', 'underline'],
                [{ align: [] }],
                ['link'],
              ],
            },
          },
          placeholder: this.$t('placeholders.typeYourMessage'),
          theme: 'snow',
        },
      };
    },
    computed: {
      mixinOptions () {
        return this.needShortOptions ? this.shortOptions : this.maxOptions;
      },
    },
    methods: {
      textClick () {
        this.$emit('focus-edit');
        this.$emit('text-click');
      },
    },
  };
</script>
<style lang="scss">
  .quill-editor {
    padding: 9px 13px;
    background-color: var(--background-color);
    max-height: 50vh;
    overflow: auto;
  }
  .ui.ql-container {
    width: 100%;
  }
  .ql-snow {
    border: none !important;
    &.ql-toolbar {
      align-self: center;
    }
  }
  .ql-picker-label {
    color: var(--text-color) !important;
  }
  .ql-picker-options {
    background: var(--background-color) !important;
    color: var(--text-color) !important;
  }
  .ql-tooltip {
    left: 0 !important;
  }
  .ql-snow .ql-tooltip {
    background: var(--background-color) !important;
    color: var(--text-color) !important;
    border: 1px solid var(--border-cl);
    box-shadow: 0 0 5px var(--tooltip-shadow);
  }
  .ql-editor.ql-blank::before {
    color: #99A9C6;
    font-style: inherit !important;
  }
  .ql-toolbar.ql-snow {
    // position: absolute;
    //border-radius: 6px;
    background: transparent;
    border-bottom: 0.5px solid var(--bg-gray-08) !important;
  }
  .ql-toolbar.ql-snow .ql-picker-label{
    //background: var(--ternary-bg-cl) !important;
    //border-radius: 3px;
    //border: 0.5px solid var(--border-cl);
  }
  .ql-picker  .ql-stroke   {
    stroke: #C2C2C2 !important;
    color: #C2C2C2 !important;
  }
  .ql-snow.ql-toolbar button, .ql-snow .ql-toolbar .ql-formats:first-child button {
    background: none;
    border: none;
    /* cursor: pointer; */
    /* display: inline-block; */
    /* float: left; */
    height: 24px;
    padding: 4px 5px;
    width: 26px;
  }
  ql-toolbar.ql-snow .ql-formats {
     margin-right: 0px;
  }
  /* .ql-snow.ql-toolbar button, .ql-snow .ql-toolbar .ql-formats:last-child button {
    margin-right: 0px !important ;
  } */
  // .ql-toolbar.ql-snow .ql-formats {
  //   margin-right: 8px;
  // }
  .ql-snow .ql-stroke {
    stroke: var(--text-color);
  }
  .ql-snow.ql-toolbar button {
    stroke: var(--text-color);
  }
  .ql-snow.ql-toolbar button:hover {
    color: #06c;
    stroke:#06c;
  }
  .ql-snow.ql-toolbar button.ql-active {
    color: #06c;
    stroke:#06c;
  }
</style>
