<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <default-select
    :items="filteredItems || []"
    :value="props.value"
    :input-id="props.inputId"
    :name="props.name"
    :placeholder="props.placeholder"
    :validation-string="props.validationString"
    :input-size="props.inputSize"
    :is-fulled="props.isFulled"
    :description="props.description"
    :is-disabled="props.isDisabled"
    :max-height="props.maxHeight"
    :read-only="true"
    :data-closable="props.dataClosable"
    :empty-state="props.emptyState"
    :icon="props.icon"
    :is-right-overflow="isRightOverflow"
    :left-img="props.leftImg"
    @select="selectItem"
    @handle-click="setActive"
  >
    <template #searchInput>
      <default-input
        :input-size="props.inputSize"
        class="pb-05 pt-05 pl-05 pr-05"
        :is-nude="true"
        :input-id="inputId"
        placeholder="Search..."
        :is-fulled="true"
        :read-only="false"
        :is-focused="active"
        @update:value="query = $event;"
      />
    </template>
  </default-select>
</template>
<script setup>
  import DefaultSelect from '@/components/base/uiKit/DefaultSelect';
  import DefaultInput from '@/components/base/uiKit/DefaultInput';

  import { ref, watch } from 'vue';

  const props = defineProps({
    items: {
      type: Array,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
      default: '',
    },
    inputId: { // нужно для label
      type: String,
      default: null,
    },
    name: { // нужно для валидации
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    validationString: {
      type: String,
      default: '',
    },
    inputSize: {
      type: String,
      default: 's',
    },
    isFulled: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: '',
    },
    maxHeight: {
      type: String,
      default: 'auto',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    dataClosable: {
      type: String,
      default: '',
    },
    emptyState: {
      type: String,
      default: 'There are no items',
    },
    icon: {
      type: String,
      default: '',
    },
    leftImg: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRightOverflow: {
      type: Boolean,
      default: true,
    },
  });
  const emit = defineEmits(['select']);
  const query = ref('');
  const filteredItems = ref(props.items);
  const active = ref(false);

  watch(() => props.items, () => (filteredItems.value = props.items));
  watch(() => query.value, makeSearch);

  function makeSearch (q) {
    const searchRegex = new RegExp(q.replace(/([.?*+^$[\]\\(){}|-])/g, ''), 'gi');
    if (q) {
      filteredItems.value = props.items.filter((item) =>
        item.name.search(searchRegex) > -1
      );
    } else {
      filteredItems.value = props.items.slice();
    }
  }
  function selectItem (item) {
    emit('select', item);
    query.value = '';
  }

  function setActive () {
    active.value = !active.value;
  }
</script>
