<template>
  <section
    ref="vldParent"
    class="inbox-page"
  >
    <div
      v-if="$route.name === 'inbox'"
      class="inbox-page__dialogs"
      :style="{ width: resizeBlock.width ? resizeBlock.width + 'px' : '300px' }"
    >
      <dialogs-inbox
        class="inbox-page__dialogs-inner"
        :search-data="searchData"
        :profiles.sync="profiles"
        @update-filters="updateSearchHandler('filters', $event)"
        @update-query="updateSearchHandler('query', $event)"
        @update-group="updateSearchHandler('group', $event)"
        @choose-profile="chooseProfileHandler"
        @update-profile="updateProfileList"
        @remove-profile="removeProfile"
      />
      <div
        ref="resizable"
        class="resize-block resize-block_right"
      />
    </div>

    <div class="inbox-page__body">
      <div
        v-if="chosenProfile._id"
        class="inbox-page__body-inner"
      >
        <header-inbox
          class="inbox-page__header"
          :active-messages-group.sync="activeMessagesGroup"
          :profile="chosenProfile"
          :profile-id-from-inbox="chosenProfile?._id || profileIdFromInbox"
          :has-old-messages-button.sync="hasOldMessagesButton"
          @update-profile-prop="updateChosenProfile"
          @change-workflow="changeWorkflow"
          @update-blacklist="updateManualBlacklist"
        />
        <chat-inbox
          :key="chosenProfile._id"
          class="inbox-page__chat"
          :profile="chosenProfile"
          :active-messages-group.sync="activeMessagesGroup"
          :has-old-messages-button.sync="hasOldMessagesButton"
          :expanded-task-id="expandedTaskId"
          :show-sender="showSender"
          @update-profile-prop="updateChosenProfile"
          @close-inbox-modal="$emit('close')"
          @refetch-info="setChosenProfile(profileIdFromInbox || chosenProfile?._id)"
        />
        <section class="inbox-page__info">
          <info-inbox
            class="inbox-page__info-inner"
            :profile="chosenProfile"
            @update-profile="updateChosenProfile"
            @update-profile-prop="updateChosenProfileProp"
            @add-phone="$emit('add-phone')"
          />
        </section>
      </div>
      <div
        v-else-if="$route.name === 'inbox'"
        class="flex-column-centered parent-size flex-gap-16 color_tertiary"
      >
        <i class="bx bx-conversation icon_size_60" />
        <p class="text_s">
          {{ $t('inboxPage.notifications.selectChat') }}
        </p>
      </div>
      <div
        v-else
        ref="vldParent"
        class="vld-parent flex-column-centered parent-size flex-gap-16 color_tertiary"
      />
    </div>
  </section>
</template>
<script>
  import DialogsInbox from '@/components/inboxPage/dialogs/DialogsInbox.vue';
  import HeaderInbox from '@/components/inboxPage/HeaderInbox.vue';
  import ChatInbox from '@/components/inboxPage/chat/ChatInbox.vue';
  import InfoInbox from '@/components/inboxPage/info/InfoInbox.vue';

  import { mapGetters } from 'vuex';
  import searchMixin from '@/mixins/inboxPage/searchMixin';
  import resizableMixin from '@/mixins/resizableMixin';
  import { loaderMixin } from '@/mixins/loaderMixin';

  import { changeWorkflowBulk, getInfo, markRead, updateManualBlacklist, updateProfile } from '@/api/profilesMethods';

  export default {
    name: 'InboxPage',
    components: {
      DialogsInbox,
      HeaderInbox,
      ChatInbox,
      InfoInbox,
    },
    mixins: [loaderMixin, searchMixin, resizableMixin],
    props: {
      profileIdFromInbox: {
        type: String,
        default: '',
      },
      messagesGroup: {
        type: String,
        default: 'messages',
      },
      targetCredentialId: {
        type: String,
        default: '',
      },
      expandedTaskId: {
        type: String,
        default: '',
      },
      showSender: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        searchData: {
          query: '',
          filters: {
            data: [],
            chosenData: {},
          },
          group: '',
        },
        profiles: [],
        chosenProfile: {},
        messages: [],
        emails: [],
        activeMessagesGroup: this.messagesGroup,
        hasOldMessagesButton: false,
      };
    },
    computed: {
      ...mapGetters({
        selectedCredential: 'credentials/selectedCredential',
      }),
      selectedCredentialId () {
        return this.targetCredentialId || this.selectedCredential._id;
      },
    },
    created () {
      if (this.$route.name === 'inbox') {
        this.searchData.filters.data = this.getFiltersData();
        this.setSearchDataFromRouter();
      }
    },
    mounted () {
      const profileId = this.$route.params.profileId || this.profileIdFromInbox;
      if (profileId) {
        this.setChosenProfile(profileId);
      }
    },
    methods: {
      async changeWorkflow (newWorkflow, startOnChange) {
        try {
          await changeWorkflowBulk({
            workflowId: newWorkflow,
            startOnChange,
            ids: [this.chosenProfile._id],
            limit: 0,
            credentialId: this.selectedCredentialId,
          });
          let message;
          message = this.$t('prospectsPage.notifications.changeWorkflow');
          if (!newWorkflow) {
            message = this.$t('prospectsPage.notifications.removeWorkflow');
          }
          this.$noty.success(message);
          this.chosenProfile.workflow = newWorkflow;
          if (startOnChange) {
            this.chosenProfile.workflow_status = 'active';
          }
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
      updateSearchHandler (type, update) {
        switch (type) {
        case 'filters': {
          this.searchData.filters.chosenData = update;
          break;
        }
        case 'query':
          this.searchData.query = update;
          break;
        case 'group':
          this.searchData.group = update;
          break;
        }
        this.updateRouter();
      },
      setSearchDataFromRouter () {
        const routeQuery = this.$route.query;
        this.searchData.query = routeQuery.query || '';
        const { statistics, workflows, filters, statuses, labels } = routeQuery;
        // сортируем, удаляя пустые ключи
        this.searchData.filters.chosenData = Object.entries({
          statistics,
          workflows,
          filters,
          statuses,
          labels,
        }).reduce((acc, [k, v]) => {
          if (v) {
            acc[k] = JSON.parse(v);
          }
          return acc;
        }, {});
        this.searchData.group = routeQuery.group || 'replied';
      },
      updateRouter (profileId = '') {
        const chosenFiltersToJSON = Object.entries(
          this.searchData.filters.chosenData
        ).reduce((acc, [k, v]) => {
          acc[k] = JSON.stringify(v);
          return acc;
        }, {});
        const query = {
          query: this.searchData.query,
          ...chosenFiltersToJSON,
          group: this.searchData.group,
        };
        profileId = profileId || this.$route.params.profileId;

        this.$router.push({
          name: this.$route.name,
          params: { profileId },
          query,
        });
      },
      chooseProfileHandler (profileId) {
        this.updateRouter(profileId);
        this.setChosenProfile(profileId);
      },
      updateProfileList (updatedProfile) {
        const idx = this.profiles.findIndex((p) => p._id === updatedProfile._id);
        this.profiles.splice(idx, 1, updatedProfile);
      },
      removeProfile (removedProfile) {
        const idx = this.profiles.findIndex((p) => p._id === removedProfile._id);
        this.profiles.splice(idx, 1);
      },
      async setChosenProfile (profileId) {
        this.loaded = false;
        try {
          const [profile] = await Promise.all([
            getInfo(profileId, this.selectedCredentialId),
            markRead(profileId, this.selectedCredentialId),
          ]);
          this.chosenProfile = profile;
          this.chosenProfile.unread_count = 0;
          this.updateProfileList(this.chosenProfile); // чтобы синхронизировать profiles и chosenProfile,
        } catch (e) {
          this.$noty.error(e.message);
        } finally {
          this.loaded = true;
        }
      },
      updateChosenProfileProp (profile) {
        this.chosenProfile = profile;
        const shouldUpdateProfilesList = this.profiles.some(
          (p) => p._id === profile._id
        );
        if (shouldUpdateProfilesList) {
          this.updateProfileList(profile);
        }
        this.$emit('update-profile-prop', profile);
      },
      async updateChosenProfile (updatedObj) {
        try {
          this.chosenProfile = await updateProfile(
            this.chosenProfile._id,
            this.selectedCredentialId,
            updatedObj
          );
          this.$emit('update-profile-prop', this.chosenProfile);
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
      async updateManualBlacklist (status) {
        try {
          await updateManualBlacklist({
            status,
            ids: [this.chosenProfile._id],
            credentialId: this.selectedCredentialId,
          });
          this.chosenProfile.blacklist_status = status;
          this.$noty.success('Profile will be updated shortly');
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
    },
    sockets: {
      update_profiles (response) {
        response = JSON.parse(response);
        if (this.chosenProfile._id === response.profile_id) {
          this.chosenProfile.enriched_data = response.enriched_data;
        }
      },
    },
  };
</script>
<style lang="scss">
@import "@/components/inboxPage/inbox";
</style>
