<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    ref="bar"
    class="progress-bar"
  >
    <div
      v-if="tip"
      ref="tip"
      class="progress-bar__tip"
      :style="style"
    >
      <p class="progress-bar__percent primary-cl">
        {{ (parseInt(percent * 100) || (total > 0 ? parseInt((progress / total) * 100) : 0 ))+ '%' }}
      </p>

      <p class="progress-bar__numbers">
        <span class="progress-bar__progress">{{ progress + ' ' }}</span>
        <span class="progress-bar__total">of {{ total }}</span>
      </p>
      <div class="progress-bar__triangle" />
    </div>
    <div
      class="progress-bar__progress primary-bg"
      :style="{ width: calculateWidth}"
    />
  </div>
</template>
<script>
  export default {
    name: 'AmazingProgressBar',
    props: {
      total: {
        type: Number,
        default: 1,
      },
      progress: {
        type: Number,
        default: 0,
      },
      percent: {
        type: Number,
        default: 0,
      },
      tip: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
        style: null,
      };
    },
    computed: {
      calculateWidth () {
        let width = parseInt(this.percent * 100) || (this.total > 0 ? parseInt((this.progress / this.total) * 100) : 0);
        if (width > 100) {
          width = 100;
        }
        return width + '%';
      },
    },
  };
</script>
<style lang="scss" scoped>
  @keyframes progress-bar-stripes {
    0% {
      background-position-x: 1rem;
    }
  }
  .progress-bar {
    position: relative;
    background: #d7d7d7;
    border-radius: 10px;
    &__triangle {
      border-width: 0 10px 8px;
      border-color: transparent;
      transform: rotate(45deg);
      border-bottom-color: #ffffff;
      position: absolute;
      bottom: -4px;
      left: calc(50% - 5px);
      width: 10px;
      height: 10px;
      border-radius: 1px;
      background-color: var(--secondary-btn-bg);
      box-shadow: 1px 2px 4px var(--tooltip-shadow);
    }
    &__tip {
      display: flex;
      flex-flow: row nowrap;
      position: absolute;
      bottom: calc(100% + 6px);
      box-shadow: 1px 2px 4px var(--tooltip-shadow);
      padding: 6px 11px;
      border-radius: 6px;
      width: fit-content;
      background-color: var(--secondary-btn-bg);
      color: var(--text-color);
    }
    &__numbers {
      color: var(--main-text-cl);
    }
    &__percent {
      margin-right: 20px;
    }
    &__progress {
      height: 8px;
      border-radius: 10px;
      animation: 1s linear infinite progress-bar-stripes;
      background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
      );
      background-size: 1rem 1rem;
    }
    p {
      font-weight: normal;
      font-size: 1.17rem;
      line-height: 20px;
    }
    &__total {
      color: var(--secondary-text-color);
    }
  }
</style>
