import { computed } from 'vue';
import { useStore } from '@/store';
import { useI18n } from '@/i18n';
const store = useStore();

export const setWorkflowFilters = (isDevSpace, isBuzzBrand) => {
  const { t } = useI18n();
  const workflowsList = computed(() => store.getters['workflows/workflowsList']);
  const dialogOutsideCampaigns = [{
    name: t('inboxPage.chatsSidebar.header.filters.showDialogsOutsideCampaigns'),
    _id: 'outside_campaigns',
  }];
  return isDevSpace || isBuzzBrand
    ? [...dialogOutsideCampaigns, ...workflowsList.value]
    : [...workflowsList.value];
};
