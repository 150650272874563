<template>
  <div class="flex-column flex-gap-16">
    <h1 class="text_s text_weight_accent pl-2 pr-2 flex-row">
      {{ $t('inboxPage.chatSection.infoSidebar.contactsInfo.title') }}

      <help-icon
        class="ml-05"
        v-bind="helpProps"
      />
    </h1>
    <div>
      <p class="text_xs color_tertiary pl-2 pr-2">
        {{ $t('inboxPage.chatSection.infoSidebar.contactsInfo.sections.direct.title') }}
      </p>
      <custom-item-contacts
        v-for="contact in directContacts"
        :key="contact.meta.key"
        class="pt-05 pr-2 pb-05 pl-2"
        :contact="contact"
        @change="change(contact.meta.profileKey, $event)"
        @add-phone="$emit('add-phone')"
      />
    </div>
    <div>
      <p class="text_xs color_tertiary pl-2 pr-2">
        {{ $t('inboxPage.chatSection.infoSidebar.contactsInfo.sections.work.title') }}
      </p>
      <custom-item-contacts
        v-for="contact in workContacts"
        :key="contact.meta.profileKey"
        class="pt-05 pr-2 pb-05 pl-2"
        :contact="contact"
        @change="change(contact.meta.profileKey, $event)"
        @add-phone="$emit('add-phone')"
      />
    </div>
    <enriched-block-contacts
      v-if="tierFeatures.statuses?.enrichment?.exist"
      :profile="profile"
      class="pr-2 pl-2"
      @update-profile="$emit('update-profile', $event)"
      @add-phone="$emit('add-phone')"
    />
  </div>
</template>
<script>
  import CustomItemContacts from '@/components/inboxPage/info/basic/contacts/CustomItemContacts';
  import EnrichedBlockContacts from '@/components/inboxPage/info/basic/contacts/EnrichedBlockContacts';
  import HelpIcon from '@/components/helpIcon/HelpIcon.vue';
  import { TooltipPosition } from '@/plugins/tooltip/enums';
  import { tierFeatures } from '@/data/tiersFeatures';

  export default {
    name: 'ContactsBasic',
    components: {
      CustomItemContacts,
      EnrichedBlockContacts,
      HelpIcon,
    },
    props: {
      profile: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        tierFeatures,
        helpProps: {
          content: 'You can enrich prospect data in two ways:<br>• Using the "Enrich Profile" step in campaigns, this data will appear in the Direct and Work categories<br>• Using the "Enrich" button on the Prospect Finder page, this data will appear in the Enriched category',
          tooltipPosition: TooltipPosition.TOP,
          textAlign: 'left',
        },
        contacts: [
          {
            name: this.$tc('common.email'),
            emptyState: this.$t('status.noEmail'),
            icon: 'bx bx-envelope',
            value: '',
            meta: {
              category: 'direct',
              profileKey: 'email',
            },
          },
          {
            name: this.$tc('common.phone'),
            emptyState: this.$t('status.noPhone'),
            icon: 'bx bx-phone',
            value: '',
            meta: {
              category: 'direct',
              profileKey: 'phone',
            },
          },
          {
            name: this.$t('servicesNames.twitter'),
            emptyState: this.$t('status.noTwitter'),
            icon: 'bx bxl-twitter',
            value: '',
            meta: {
              category: 'direct',
              profileKey: 'twitter',
            },
          },

          {
            name: this.$tc('common.email'),
            emptyState: this.$t('status.noEmail'),
            icon: 'bx bx-envelope',
            value: '',
            meta: {
              category: 'work',
              profileKey: 'work_email',
            },
          },
          // {
          //   name: this.$tc('common.phone'),
          //   emptyState: this.$t('status.noPhone'),
          //   icon: 'bx bx-phone',
          //   value: '',
          //   meta: {
          //     category: 'work',
          //     profileKey: 'company_phone',
          //   },
          // },
        ],
      };
    },
    computed: {
      directContacts () {
        return this.contacts.filter(c => c.meta.category === 'direct').map(c => {
          c.profile = this.profile;
          return c;
        });
      },
      workContacts () {
        return this.contacts.filter(c => c.meta.category === 'work');
      },
    },
    watch: {
      'profile._id' () {
        this.setContacts();
      },
    },
    created () {
      this.setContacts();
    },
    methods: {
      setContacts () {
        this.contacts = this.contacts.map(c => {
          c.value = this.profile[c.meta.profileKey];
          return c;
        });
      },
      change (key, newValue) {
        this.contacts = this.contacts.map(c => {
          if (c.meta.profileKey === key) {
            c.value = newValue;
          }
          return c;
        });
        const updatedProp = { [key]: newValue };
        this.$emit('update-profile', updatedProp);
      },
    },
  };
</script>
