<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    :class="[
      'uikit-label',
      'uikit-label_size_' + size,
      { 'cursor-pointer': hasButton },
    ]"
    :style="label ? labelStyle : customStyle"
  >
    <i
      v-if="iconName"
      class="uikit-label__icon bx mr-05"
      :class="iconName"
    />
    <p>
      {{ label?.name || labelName }}
    </p>
    <i
      v-if="hasButton"
      class="bx bx-x icon_size_16"
    />
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import variables from '@/styles/_variables.module.scss';

  const props = defineProps({
    label: {
      type: Object,
      default: null,
    },
    size: {
      type: String,
      required: true,
    },
    labelName: {
      type: String,
      default: '',
    },
    customType: {
      type: String,
      default: '',
    },
    hasButton: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      default: '',
    },
  });

  const labelStyle = computed(() => {
    return {
      background: props.label.color ? props.label.color + '1A' : '',
      color: props.label.color,
    };
  });

  const customStyle = computed(() => {
    return {
      background: variables[`cl-${props.customType}-bg`],
      color: variables[`cl-${props.customType}`],
    };
  });
</script>

<style lang="scss">
.uikit-label {
  @include flex-row-centered;
  border-radius: 4px;
  white-space:  nowrap;

  &__icon {
    font-weight: inherit
  }

  &_size {
    &_m {
      @include text_m;
      padding: 4px 8px;
    }

    &_s {
      @include text_s;
      padding: 2px 6px;
    }

    &_xs {
      @include text_xs;
      padding: 2px 6px;
    }
  }
}
</style>
