export class FeatureStatus {
    active;
    upgrade;
    exist;
    constructor(active, upgrade) {
        this.active = active;
        this.upgrade = upgrade;
        this.exist = active || upgrade;
    }
}
